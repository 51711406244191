import axiosIns from '@/libs/axios'

export default {
  getAll: () => axiosIns.get('accounting/coa-config/data'),
  create: payload => axiosIns.post('accounting/coa-config/store', payload),
  getOne: id => axiosIns.get(`accounting/coa-config/${id}`),
  update: (payload, id) => axiosIns.put(`accounting/coa-config/update/${id}`, payload),
  delete: id => axiosIns.put(`accounting/coa-config/delete/${id}`),
  getHeadType: () => axiosIns.get('accounting/coa-config/head-type'),
}
