import axiosIns from '@/libs/axios'

export default {
  getAll: () => axiosIns.get('accounting/coa-list'),
  saveCoa: params => axiosIns.post('accounting/store', params),
  updateCoa: (id, params) => axiosIns.put(`accounting/update/${id}`, params),
  deleteCoa: coaId => axiosIns.put(`accounting/delete/${coaId}`),
  mainCoa: parentId => axiosIns.get(`accounting/main-coa/${parentId}`),
  childCoa: parentId => axiosIns.get(`accounting/child-coa/${parentId}`),
}
