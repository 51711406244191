  <!-- pagination -->
<template
  slot="pagination-bottom"
  slot-scope="props"
>

  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center mb-0 mt-1">
      <span class="text-nowrap"> Showing 1 to </span>
      <b-form-select
        v-model="perPage"
        :options="perPageOptions"
        class="mx-1"
        @input="(value) =>isRemote? onPerPageChange(value): paginationProps.perPageChanged({ currentPerPage: value })"
      />
      <span class="text-nowrap"> of {{ paginationProps.total }} entries </span>
    </div>
    <!-- {{ JSON.stringify(paginationProps) }} -->
    <div class="d-flex">
      <div class="mr-2">
        <b-pagination
          :value="isRemote ? currentPage : 1"
          :total-rows="paginationProps.total"
          :per-page="perPage"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) =>isRemote? onPageChange(value) : paginationProps.pageChanged({ currentPerPage: value })"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <div
        slot="table-actions"
        class="mt-1"
      >
        <b-button
          v-if="refreshGrid"
          class="btn-sm mr-1 px-1 "
          variant="outline-primary"
          @click="refreshGrid()"
        >
          <RefreshCwIcon />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination, BFormSelect, BButton } from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { RefreshCwIcon } from 'vue-feather-icons'

export default {
  components: {
    BPagination,
    BFormSelect,
    BButton,
    RefreshCwIcon,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    paginationProps: Object,
    // eslint-disable-next-line vue/require-default-prop
    refreshGrid: Function,
    // eslint-disable-next-line vue/require-default-prop
    isRemote: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    pageLength: Number,
    // eslint-disable-next-line vue/require-default-prop
    currentPage: Number,
    // eslint-disable-next-line vue/require-default-prop
    onPageChange: Function,
    // eslint-disable-next-line vue/require-default-prop
    onPerPageChange: Function,
  },
  data() {
    return {
      perPageOptions: ['1', '2', '10', '20', '30', '40', '50'],
      perPage: this.pageLength || 10,

    }
  },
  computed: {
  },
  created() {
  },
  methods: {

  },
  /*
  * custom payment
  */
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
