b-form-group<template>
  <b-card>
    <b-modal
      id="coa-modal"
      no-close-on-backdrop
      title="Add Config Head"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="saveCOAConfig"
      @ok.prevent
      @cancel="resetForm"
      @close="resetForm"
    >
      <div>
        <!-- Fixed Child -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Is Fixed COA"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="isFixedChild"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                :style="{ marginTop: '.5rem' }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Head Type -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="headTypeFilterState"
              invalid-feedback="Head Type is Required"
            >
              <label>Head Type</label>
              <v-select
                v-model="headTypeFilter"
                placeholder="Select Head Type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="headTypeOptions"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Account Type -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="accountTypeFilterState"
              invalid-feedback="Account Type is Required"
            >
              <label>Account Type</label>
              <v-select
                v-model="accountTypeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="accountTypeOptions"
                :reduce="(val) => val.id"
                placeholder="Select Account Type"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Main Head -->
        <b-row v-if="accountTypeFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label>Main Head</label>
              <v-select
                v-model="mainHeadFilter"
                placeholder="Select Main Head"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="mainHeadOptions"
                :disabled="!accountTypeFilter"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Sub Head -->
        <b-row v-if="mainHeadFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label>Sub Head</label>
              <v-select
                v-model="subHeadFilter"
                placeholder="Select Sub Head"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subHeadOptions"
                label="gl_name"
                :disabled="!mainHeadFilter"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Child Head -->
        <b-row v-if="subHeadFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label>Child Head</label>
              <v-select
                v-model="childHeadFilter"
                placeholder="Select Child Head"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="childHeadOptions"
                :disabled="!subHeadFilter"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Child Sub Head -->
        <b-row v-if="childHeadFilter && isFixedChild === '1'">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label>Child Sub Head</label>
              <v-select
                v-model="childSubHeadFilter"
                placeholder="Select Child Sub Head"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="childSubHeadOptions"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        COA Config Head
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.coa-modal
          variant="outline-primary"
        >
          Add Config Head
        </b-button>
      </b-form-group>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      v-if="!isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        searchFn: globalGridSearch,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Head Type -->
        <span v-if="props.column.field === 'isFixedChild'">
          {{ props.row.is_fixed_child===1 ? "Yes" : 'No' }}
        </span>
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteCOAConfig(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Head Type -->
        <span v-else-if="props.column.field === 'head_type'">
          {{ props.formattedRow[props.column.field].name }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field].gl_name }}
        </span>
      </template>

      <!--  pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <Pagination
          :v-if="props"
          :pagination-props="props"
          :refresh-grid="refreshGrid"
        />
      </template>
    </vue-good-table>
    <preloader v-if="isLoading" />

    <b-modal
      id="coa-config-edit-modal"
      no-close-on-backdrop
      title="Update COA Config"
      ok-title="Update"
      cancel-variant="outline-secondary"
      @ok="updateCOAConfig"
    >
      <b-form>
        <!-- Fixed Child -->

        <b-form-group
          label="Is Fixed COA"
          label-cols-md="4"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="isFixedChild"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
            :style="{ marginTop: '.5rem' }"
          />
        </b-form-group>
        <!-- Head Type -->
        <b-form-group
          label="Head Type"
          label-for="head-type-filter"
        >
          <v-select
            v-model="headTypeValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="headTypeOptions"
            :reduce="(val) => val.id"
            :disabled="accountTypeValue ? true : false"
          />
        </b-form-group>
        <!-- Account Type -->
        <b-form-group
          v-if="headTypeValue"
          label="Account Type"
          label-for="account-type-filter"
        >
          <v-select
            v-model="accountTypeValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="accountTypeOptions"
            :reduce="(val) => val.id"
            :disabled="mainHeadValue ?true :false"
          />
        </b-form-group>
        <!-- Main Head -->
        <b-form-group
          v-if="accountTypeValue"
          label="Main Head"
          label-for="main-head-filter"
        >
          <v-select
            v-model="mainHeadValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="mainHeadOptions"
            :reduce="(val) => val.id"
            :disabled="subHeadValue ?true :false"
          />
        </b-form-group>
        <!-- Sub Head -->
        <b-form-group
          v-if="mainHeadValue"
          label="Sub Head"
          label-for="sub-head-filter"
        >
          <v-select
            v-model="subHeadValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="subHeadOptions"
            :reduce="(val) => val.id"
            :disabled="childHeadValue ? true : false"
          />
        </b-form-group>
        <!-- Child Head -->
        <b-form-group
          v-if="subHeadValue"
          label="Child Head"
          label-for="child-head-filter"
        >
          <v-select
            v-model="childHeadValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="childHeadOptions"
            :reduce="(val) => val.id"
            :disabled="childSubHeadValue ? true : false"
          />
        </b-form-group>
        <!-- Child Sub Head -->
        <b-form-group
          v-if="childHeadValue && isFixedChild === '1'"
          label="Child Sub Head"
          label-for="child-sub-head-filter"
        >
          <v-select
            v-model="childSubHeadValue"
            placeholder="Select Child Sub Head"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="childSubHeadOptions"
            :reduce="(val) => val.id"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  VBModal,
  BButton,
  BFormCheckbox, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

import Pagination from '@/views/components/vue-good-table-pagination/Pagination.vue'
import AccCoaConfigService from '@/@service/api/accounting/AccCoaConfig.service'
import Preloader from '../components/preloader/preloader.vue'
import ChartOfAccountsService from '@/@service/api/accounting/ChartOfAccounts.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BButton,
    BFormCheckbox,
    Pagination,
    Preloader,
    ActivityModal,

    BLink,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isLoading: false,
      updateId: null,
      selectedModal: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Head Type',
          width: '130px',
          field: 'head_type',
          filterOptions: {
            enabled: true,
            placeholder: 'All Head Types',
            filterFn: this.headTypeFilterFn,
            filterDropdownItems: [
              { text: 'Debit Voucher (Debit Head)', value: '1' },
              { text: 'Debit Voucher (Credit Head)', value: '2' },
              { text: 'Journal Voucher (Debit Head)', value: '3' },
              { text: 'Journal Voucher (Credit Head)', value: '4' },
              { text: 'Advance Payment (Debit Head)', value: '5' },
              { text: 'Advance payment (Credit Head)', value: '6' },
              { text: 'Credit Voucher (Debit Head)', value: '7' },
              { text: 'Credit Voucher (Credit Head)', value: '8' },
            ],
          },
          sortable: false,
        },
        {
          label: 'Account Type',
          width: '160px',
          field: 'account_type',
          filterOptions: {
            enabled: true,
            placeholder: 'All Account Types',
            filterFn: this.accountTypeFilterFn,
            filterDropdownItems: [
              { text: 'Asset', value: '1' },
              { text: 'Liabilities & Equity', value: '2' },
              { text: 'Income', value: '3' },
              { text: 'Expense', value: '4' },
            ],
          },
          sortable: false,
        },
        {
          label: 'Main Head',
          width: '130px',
          field: 'main_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Main Head',
          },
          sortable: false,
        },
        {
          label: 'Sub Head',
          width: '130px',
          field: 'sub_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Sub Head',
          },
          sortable: false,
        },
        {
          label: 'Child Head',
          width: '130px',
          field: 'child_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Child Head',
          },
          sortable: false,
        },
        {
          label: 'Child Sub Head',
          width: '168px',
          field: 'child_sub_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Child Sub Head',
          },
          sortable: false,
        },
        {
          label: 'Is Fixed COA',
          width: '131px',
          field: 'isFixedChild',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      headTypeValue: '',
      accountTypeValue: '',
      mainHeadValue: '',
      subHeadValue: '',
      childHeadValue: '',
      childSubHeadValue: '',
      headTypeFilter: null,
      accountTypeFilter: null,
      mainHeadFilter: null,
      subHeadFilter: null,
      childHeadFilter: null,
      childSubHeadFilter: null,
      subChildHeadFilter: null,
      glNameFilter: null,
      addSubHeadField: false,
      addChildHeadField: false,
      addSubChildHeadField: false,
      coaEditData: null,
      isFixedChild: 0,
      headTypeOptions: [
        { label: 'Debit Voucher (Debit Head)', id: '1' },
        { label: 'Debit Voucher (Credit Head)', id: '2' },
        { label: 'Journal Voucher (Debit Head)', id: '3' },
        { label: 'Journal Voucher (Credit Head)', id: '4' },
        { label: 'Advance Payment (Debit Head)', id: '5' },
        { label: 'Advance payment (Credit Head)', id: '6' },
        { label: 'Credit Voucher (Debit Head)', id: '7' },
        { label: 'Credit Voucher (Credit Head)', id: '8' },
        { label: 'Concession Fee Head', id: '9' },
      ],
      accountTypeOptions: [
        { gl_name: 'Asset', id: '1' },
        { gl_name: 'Liabilities & Equity', id: '2' },
        { gl_name: 'Income', id: '3' },
        { gl_name: 'Expense', id: '4' },
      ],
      mainHeadOptions: [],
      subHeadOptions: [],
      childHeadOptions: [],
      childSubHeadOptions: [],
      subChildHeadOptions: [],

      headTypeFilterState: null,
      accountTypeFilterState: null,

      // Activity Log Data
      tag: 'chart-of-account-config-head',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    accountTypeFilter(newVal) {
      if (newVal == null) {
        this.mainHeadFilter = null
        this.subHeadFilter = null
        this.childHeadFilter = null
        this.childSubHeadFilter = null
      } else if (typeof newVal === 'object') {
        this.getMainCoa(newVal.id)
      } else {
        this.getMainCoa(newVal)
      }
    },
    mainHeadFilter(newVal) {
      if (newVal == null) {
        this.subHeadFilter = null
        this.childHeadFilter = null
      } else if (typeof newVal === 'object') {
        this.getChildCoa(newVal.id, 'subHead')
      } else {
        this.getChildCoa(newVal, 'subHead')
      }
    },
    subHeadFilter(newVal) {
      if (newVal == null) {
        this.childHeadFilter = null
      } else if (typeof newVal === 'object') {
        this.getChildCoa(newVal.id, 'childHead')
      } else {
        this.getChildCoa(newVal, 'childHead')
      }
    },
    childHeadFilter(newVal) {
      if (newVal == null) {
        this.childSubHeadFilter = null
      } else if (typeof newVal === 'object') {
        this.getChildCoa(newVal.id, 'childSubHead')
      } else {
        this.getChildCoa(newVal, 'childSubHead')
      }
    },

    accountTypeValue(newVal) {
      if (!newVal) return
      if (typeof newVal === 'object') {
        this.getMainCoa(newVal.id)
        return
      }
      this.getMainCoa(newVal)
    },
    mainHeadValue(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getChildCoa(newVal, 'subHead')
      }
    },
    subHeadValue(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getChildCoa(newVal, 'childHead')
      }
    },
    childHeadValue(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getChildCoa(newVal, 'childSubHead')
      }
    },
  },
  created() {
    this.getCOAConfigGridData()
    this.getHeadTypeOptions()
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.selectedModal = null
      this.glNameFilter = null
    })

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (bvEvent.type === 'show' && modalId === 'coa-modal') this.resetForm()
    })
  },
  methods: {
    getCOAConfigGridData() {
      this.isLoading = true
      AccCoaConfigService.getAll().then(res => {
        this.rows = res.data.data
        this.isLoading = false
      })
        .catch(error => {
          FemsToastrService.error(error.message)
          this.isLoading = false
        })
    },
    refreshGrid() {
      this.isLoading = true

      this.getCOAConfigGridData()
    },
    advanceSearch() {

    },
    formValidation() {
      this.headTypeFilterState = null
      this.accountTypeFilterState = null

      if (this.headTypeFilter === null || this.headTypeFilter === '') {
        this.headTypeFilterState = false
      }
      if (this.accountTypeFilter === null || this.accountTypeFilter === '') {
        this.accountTypeFilterState = false
      }
      if (
        this.headTypeFilterState !== false
        && this.accountTypeFilterState !== false
      ) {
        return true
      }
      return false
    },
    saveCOAConfig() {
      if (!this.formValidation()) {
        return
      }
      this.isLoading = true

      const params = {
        head_type: this.headTypeFilter,
        coa_id:
          this.isFixedChild === '1'
            ? this.childSubHeadFilter
              || this.childHeadFilter
              || this.subHeadFilter
              || this.mainHeadFilter
              || this.accountTypeFilter
            : this.childHeadFilter
              || this.subHeadFilter
              || this.mainHeadFilter
              || this.accountTypeFilter,
        is_fixed_child: this.isFixedChild,
      }

      AccCoaConfigService.create(params)
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.getCOAConfigGridData()
              FemsToastrService.success(res.data.message)
              this.$bvModal.hide('coa-modal')
            } else {
              FemsToastrService.error(res.data.message)
            }
          } else {
            FemsToastrService.error(res.data.message)
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          FemsToastrService.error(error.message)
        })
    },
    updateCOAConfig() {
      this.isLoading = true
      console.log(this.updateId)

      const params = {
        head_type: this.headTypeValue,
        coa_id:
          this.isFixedChild === '1'
            ? this.childSubHeadValue
              || this.childHeadValue
              || this.subHeadValue
              || this.mainHeadValue
              || this.accountTypeValue
            : this.childHeadValue
              || this.subHeadValue
              || this.mainHeadValue
              || this.accountTypeValue,
        is_fixed_child: this.isFixedChild,
      }

      AccCoaConfigService.update(params, this.updateId)
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.glNameFilter = null
              this.selectedModal = null
              this.getCOAConfigGridData()
              FemsToastrService.success(res.data.message)
            } else {
              FemsToastrService.error(res.data.message)
            }
          } else {
            FemsToastrService.error(res.data.message)
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          FemsToastrService.error(error.message)
        })
    },
    headTypeFilterFn(data, filterString) {
      return data.id === Number(filterString)
    },
    accountTypeFilterFn(data, filterString) {
      return data.id === Number(filterString)
    },
    columnFilterFn(data, filterString) {
      return (
        data
        && data.gl_name
        && data.gl_name.toLowerCase().includes(filterString.toLowerCase())
      )
    },
    getHeadTypeOptions() {
      AccCoaConfigService.getHeadType().then(res => {
        if (res.data.status) {
          this.headTypeFilter = null
          this.headTypeOptions = res.data.data
        }
      })
    },
    getMainCoa(parentId) {
      ChartOfAccountsService.mainCoa(parentId).then(res => {
        this.mainHeadFilter = null
        this.mainHeadOptions = res.data.data
      })
    },

    getChildCoa(parentId, target = null) {
      ChartOfAccountsService.childCoa(parentId).then(res => {
        if (target === 'subHead') {
          this.subHeadFilter = null
          this.subHeadOptions = res.data.data
        } else if (target === 'childHead') {
          this.childHeadFilter = null
          this.childHeadOptions = res.data.data
        } else if (target === 'subChildHead') {
          this.subChildHeadFilter = null
          this.subChildHeadOptions = res.data.data
        } else if (target === 'childSubHead') {
          this.childSubHeadFilter = null
          this.childSubHeadOptions = res.data.data
        }
      })
      // console
    },
    globalGridSearch(row, col, cellValue, searchTerm) {
      return (
        cellValue
        && cellValue.gl_name
        && cellValue.gl_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    },
    editRow(rowData) {
      this.resetForm()

      this.coaEditData = rowData
      if (rowData.head_type) {
        this.headTypeValue = rowData.head_type.id
      }

      if (rowData.account_type) {
        this.accountTypeValue = rowData.account_type
      }

      if (rowData.main_head) {
        this.mainHeadValue = rowData.main_head.id
      }

      if (rowData.sub_head) {
        this.subHeadValue = rowData.sub_head.id
      }

      if (rowData.child_head) {
        this.childHeadValue = rowData.child_head.id
      }

      if (rowData.child_sub_head) {
        this.childSubHeadValue = rowData.child_sub_head.id
      }

      this.isFixedChild = rowData.is_fixed_child === 1 ? '1' : '0'
      this.updateId = rowData.id
      this.$bvModal.show('coa-config-edit-modal')
    },

    deleteCOAConfig(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          AccCoaConfigService.delete(rowData.id).then(res => {
            if (res.status && res.data.status) {
              this.getCOAConfigGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res.data.message)
            }
          })
        }
      })
    },
    resetForm() {
      this.headTypeFilter = null
      this.accountTypeFilter = null
      this.mainHeadFilter = null
      this.subHeadFilter = null
      this.childHeadFilter = null
      this.childSubHeadFilter = null
      this.subChildHeadFilter = null
      this.headTypeValue = null
      this.accountTypeValue = null
      this.mainHeadValue = null
      this.subHeadValue = null
      this.childHeadValue = null
      this.childSubHeadValue = null
      this.isFixedChild = '0'
      this.headTypeFilterState = null
      this.accountTypeFilterState = null
    },

    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
